export default [
  {
    key: "data",
    name: "基础数据",
    icon: "table",
    submenus: [
      { key: "/data/crossing", name: "道口管理" },
      { key: "/data/con", name: "Con" },
      { key: "/data/department", name: "Department" },
      { key: "/data/airport", name: "Airport" },
      { key: "/data/destination_location", name: "Destination Location" },
      { key: "/data/country", name: "Contry" },
      { key: "/data/residential", name: "Residential" },
      { key: "/data/postal_code", name: "Postal Code" },
      { key: "/data/sensitive", name: "敏感品" },
      { key: "/data/packing_code", name: "Packing Code" },
    ],
  },
  {
    key: "sorting",
    name: "分拣管理",
    icon: "filter",
    submenus: [
      { key: "/sorting/sorting_rule", name: "分拣规则" },
      { key: "/sorting/package_interception", name: "包裹拦截" },
      { key: "/sorting/overlimit_interception", name: "超限拦截" },
      { key: "/sorting/sensitive_interception", name: "敏感品拦截" },
      { key: "/sorting/package", name: "分拣计划" },
      { key: "/sorting/sorting_record", name: "分拣记录" },
      { key: "/sorting/sorting_report", name: "分拣报告" },
      { key: "/sorting/startime_upload_record", name: "米仓上传记录" },
      { key: "/sorting/shein_upload_record", name: "希音上传记录" },
      { key: "/sorting/bind_con_record", name: "集 Con 记录" },
    ],
  },
  {
    key: "calibration",
    name: "校准模块",
    icon: "security-scan",
    submenus: [
      { key: "/calibration/standard_package", name: "标准件" },
      { key: "/calibration/measurement_task", name: "测量任务" },
    ],
  },
  {
    key: "log",
    name: "日志管理",
    icon: "file-text",
    submenus: [
      { key: "/log/dws_upload_log_a", name: "DWS 上传日志 A" },
      { key: "/log/dws_upload_log_b", name: "DWS 上传日志 B" },
      { key: "/log/dws_upload_log_c", name: "DWS 上传日志 C" },
      { key: "/log/plc_listen_log_a", name: "PLC 监听日志 A" },
      { key: "/log/plc_listen_log_b", name: "PLC 监听日志 B" },
      { key: "/log/fedex_upload_log", name: "Fedex 上传日志" },
      { key: "/log/shein_import_log", name: "Shein 导入日志" },
      { key: "/log/shein_upload_log", name: "Shein 回传日志" },
      { key: "/log/startime_upload_log", name: "Startime 回传日志" },
    ],
  },
  {
    key: "system",
    name: "系统管理",
    icon: "team",
    submenus: [
      { key: "/system/role", name: "角色管理" },
      { key: "/system/account", name: "员工账号" },
      { key: "/system/config", name: "系统配置" },
      { key: "/system/mobile_download", name: "移动端下载" },
    ],
  },
];
